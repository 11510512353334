<template>
  <div class="footer bg-deep-purple">
    <p>Shoutouts to Racer, Phen & Toon (and simpleflips ig)</p>
  </div>
</template>

<style>
.footer {
  z-index: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  color: black;
  text-align: center;
  line-height: 30px;
}
</style>
